import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 4,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'RIM-ROSE',
    tag: 'Wormhole',
    lpAddresses: {
      97: '',
      42262: '0xf6e0656162c471e27bcf23ab6626ad5f66b70e18',
    },
    tokenSymbol: 'RIM',
    tokenAddresses: {
      97: '',
      42262: '0x363E23D1e0c8b06Ff0684f7Ed6eFE0A83541a424',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 6,
    risk: 5,
    lpSymbol: 'RIM-USDC',
    tag: 'EvoDefi',
    lpAddresses: {
      97: '',
      42262: '0x7a4752b363b261cf8982a999f9e1ccaf8ebd22fa',
    },
    tokenSymbol: 'RIM',
    tokenAddresses: {
      97: '',
      42262: '0x363E23D1e0c8b06Ff0684f7Ed6eFE0A83541a424',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'RIM-USDT',
    tag: 'Wormhole',
    lpAddresses: {
      97: '',
      42262: '0x29008d0951eb88b65de3658526d6d2b60f3cc5e2',
    },
    tokenSymbol: 'RIM',
    tokenAddresses: {
      97: '',
      42262: '0x363E23D1e0c8b06Ff0684f7Ed6eFE0A83541a424',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busdWorm,
  },

  {
    pid: 0,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'ROSE-USDT',
    tag: 'Wormhole',
    lpAddresses: {
      97: '',
      42262: '0x8d82559a91929dd72682f5c6e2eea3905b6c2a18',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      42262: contracts.busd[42262],
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    risk: 5,
    lpSymbol: 'ROSE-ETH',
    tag: 'Wormhole',
    lpAddresses: {
      97: '',
      42262: '0x1ba37943f2346f8d1b7264a65a404d416b3e9eea',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      42262: '0x3223f17957Ba502cbe71401D55A0DB26E5F7c68F',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 2,
    risk: 5,
    lpSymbol: 'USDT-USDC',
    tag: 'Wormhole',
    lpAddresses: {
      97: '',
      42262: '0xd6b7c10149799f1d9dd0202ccce8e7a34d51eb6b',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      42262: '0xE8A638b3B7565Ee7c5eb9755E58552aFc87b94DD',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busdWorm,
  },

  {
    pid: 5,
    risk: 5,
    lpSymbol: 'RIM',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      42262: '0xf6e0656162c471e27bcf23ab6626ad5f66b70e18',
    },
    tokenSymbol: 'RIM',
    tokenAddresses: {
      97: '',
      42262: '0x363E23D1e0c8b06Ff0684f7Ed6eFE0A83541a424',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 7,
    risk: 5,
    lpSymbol: 'USDT-USDC',
    tag: 'EvoDefi',
    lpAddresses: {
      97: '',
      42262: '0x113e9417d6d09dad42a5e02c479ff6e0e25873ee',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      42262: '0x94fbfFe5698DB6f54d6Ca524DbE673a7729014Be',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 8,
    risk: 5,
    lpSymbol: 'ROSE-BTC',
    tag: 'EvoDefi',
    lpAddresses: {
      97: '',
      42262: '0xf8554cd630c414d3bd060bb8cb4457a16a8d250c',
    },
    tokenSymbol: 'BTC',
    tokenAddresses: {
      97: '',
      42262: '0x010CDf0Db2737f9407F8CFcb4dCaECA4dE54c815',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 9,
    risk: 5,
    lpSymbol: 'ROSE-USDC',
    tag: 'EvoDefi',
    lpAddresses: {
      97: '',
      42262: '0xfb20f9a94d8959b260a13389d940c2010f7a9fb9',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      42262: contracts.usdc[42262],
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 10,
    risk: 5,
    lpSymbol: 'BTC-ETH',
    tag: 'EvoDefi',
    lpAddresses: {
      97: '',
      42262: '0x1c3d84981ae4b78ff53a188482afed06dbee1f03',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      42262: contracts.eth[42262],
    },
    quoteTokenSymbol: QuoteToken.BTC,
    quoteTokenAdresses: contracts.btc,
  },
]

export default farms
