import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeUpdatedIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeUpdatedIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://dex.rimswap.app/', // 'https://dex.rimswap.app/',
      },
      {
        label: 'Liquidity',
        href: 'https://dex.rimswap.app/#/pool', // 'https://dex.rimswap.app/#/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmUpdatedIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'LotteryIcon',
    href: '/pools',
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'PoolUpdatedIcon',
  //   href: '/lottery',
  // },
  {
    label: 'NFT',
    icon: 'NftIcon',
    href: 'https://www.oasisgorillas.com/',
  },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'PancakeSwap',
  //       href: 'https://pancakeswap.info/token/0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: 'https://www.coingecko.com/en/coins/goose-finance',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: 'https://coinmarketcap.com/currencies/goose-finance/',
  //     },
  //     {
  //       label: 'AstroTools',
  //       href: 'https://app.astrotools.io/pancake-pair-explorer/0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
  //     },
  //   ],
  // },

  {
    label: 'Bridges',
    icon: 'PoolIcon',
    items: [
      {
        label: 'EvoDefi',
        href: 'https://bridge.evodefi.com/',
      },
      {
        label: 'WormHole',
        href: 'https://portalbridge.com/#/transfer',
      },
    ],
  },

  {
    label: 'Socials',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Twitter',
        href: 'https://twitter.com/RIMSwap',
      },
      {
        label: 'Telegram',
        href: 'https://t.me/Rimswap_chat',
      },
      {
        label: 'Discord',
        href: 'https://discord.gg/uXWSuCdQcr',
      },
    ],
  },

  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Contact',
        href: 'https://t.me/Rimswap_chat/',
      },
      {
        label: 'Docs',
        href: 'https://bit.ly/3w16iGb',
      },
      {
        label: 'Tokenomics',
        href: 'https://bit.ly/3J62K9o',
      },
      {
        label: 'Roadmap',
        href: 'https://bit.ly/3w3GTf7',
      },
    ],
  },
]

export default config
